/* tslint:disable */
/* eslint-disable */
/**
 * 中间件接口文档
 * 聪耀，轻流中间件接口文档
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: qgm1993@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 单据类型
 * @export
 */
export const TaskServiceClassify = {
    B2B_RETURN: 'B2B_RETURN',
    B2C_RETURN: 'B2C_RETURN',
    B2C_CHANGE: 'B2C_CHANGE'
} as const;
export type TaskServiceClassify = typeof TaskServiceClassify[keyof typeof TaskServiceClassify];


export function TaskServiceClassifyFromJSON(json: any): TaskServiceClassify {
    return TaskServiceClassifyFromJSONTyped(json, false);
}

export function TaskServiceClassifyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskServiceClassify {
    return json as TaskServiceClassify;
}

export function TaskServiceClassifyToJSON(value?: TaskServiceClassify | null): any {
    return value as any;
}

