/* tslint:disable */
/* eslint-disable */
/**
 * 中间件接口文档
 * 聪耀，轻流中间件接口文档
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: qgm1993@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskServiceClassify } from './TaskServiceClassify';
import {
    TaskServiceClassifyFromJSON,
    TaskServiceClassifyFromJSONTyped,
    TaskServiceClassifyToJSON,
} from './TaskServiceClassify';
import type { TaskStage } from './TaskStage';
import {
    TaskStageFromJSON,
    TaskStageFromJSONTyped,
    TaskStageToJSON,
} from './TaskStage';
import type { TaskStatus } from './TaskStatus';
import {
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
} from './TaskStatus';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 单据编号
     * @type {string}
     * @memberof Task
     */
    billCode: string;
    /**
     * 目标仓库
     * @type {string}
     * @memberof Task
     */
    warehouse?: string;
    /**
     * 
     * @type {TaskServiceClassify}
     * @memberof Task
     */
    serviceClassify?: TaskServiceClassify;
    /**
     * 报文信息
     * @type {string}
     * @memberof Task
     */
    report?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof Task
     */
    status?: TaskStatus;
    /**
     * 
     * @type {TaskStage}
     * @memberof Task
     */
    stage?: TaskStage;
    /**
     * Id
     * @type {number}
     * @memberof Task
     */
    id?: number;
    /**
     * 创建时间
     * @type {Date}
     * @memberof Task
     */
    createdAt?: Date;
    /**
     * 修改时间
     * @type {Date}
     * @memberof Task
     */
    modifiedAt?: Date;
}

/**
 * Check if a given object implements the Task interface.
 */
export function instanceOfTask(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "billCode" in value;

    return isInstance;
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billCode': json['billCode'],
        'warehouse': !exists(json, 'warehouse') ? undefined : json['warehouse'],
        'serviceClassify': !exists(json, 'serviceClassify') ? undefined : TaskServiceClassifyFromJSON(json['serviceClassify']),
        'report': !exists(json, 'report') ? undefined : json['report'],
        'status': !exists(json, 'status') ? undefined : TaskStatusFromJSON(json['status']),
        'stage': !exists(json, 'stage') ? undefined : TaskStageFromJSON(json['stage']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function TaskToJSON(value?: Task | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billCode': value.billCode,
        'warehouse': value.warehouse,
        'serviceClassify': TaskServiceClassifyToJSON(value.serviceClassify),
        'report': value.report,
        'status': TaskStatusToJSON(value.status),
        'stage': TaskStageToJSON(value.stage),
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
    };
}

