/* tslint:disable */
/* eslint-disable */
/**
 * 中间件接口文档
 * 聪耀，轻流中间件接口文档
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: qgm1993@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 任务阶段
 * @export
 */
export const TaskStage = {
    RECEIVED: 'RECEIVED',
    SEND_TO_QF: 'SEND_TO_QF',
    QF_RECEIVED: 'QF_RECEIVED',
    QF_EXPRESS_ORDER: 'QF_EXPRESS_ORDER',
    CALLBACK: 'CALLBACK',
    RECEIVED_UPDATE: 'RECEIVED_UPDATE',
    SEND_TO_QF_UPDATE: 'SEND_TO_QF_UPDATE',
    QF_RECEIVED_UPDATE: 'QF_RECEIVED_UPDATE',
    RECEIVED_CANCEL: 'RECEIVED_CANCEL',
    SEND_TO_QF_CANCEL: 'SEND_TO_QF_CANCEL',
    QF_RECEIVED_CANCEL: 'QF_RECEIVED_CANCEL'
} as const;
export type TaskStage = typeof TaskStage[keyof typeof TaskStage];


export function TaskStageFromJSON(json: any): TaskStage {
    return TaskStageFromJSONTyped(json, false);
}

export function TaskStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStage {
    return json as TaskStage;
}

export function TaskStageToJSON(value?: TaskStage | null): any {
    return value as any;
}

