/* tslint:disable */
/* eslint-disable */
/**
 * 中间件接口文档
 * 聪耀，轻流中间件接口文档
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: qgm1993@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskStage } from './TaskStage';
import {
    TaskStageFromJSON,
    TaskStageFromJSONTyped,
    TaskStageToJSON,
} from './TaskStage';
import type { TaskStatus } from './TaskStatus';
import {
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
} from './TaskStatus';

/**
 * 
 * @export
 * @interface Log
 */
export interface Log {
    /**
     * taskId
     * @type {number}
     * @memberof Log
     */
    taskId?: number;
    /**
     * 单据编号
     * @type {string}
     * @memberof Log
     */
    billCode: string;
    /**
     * 单据类型
     * @type {string}
     * @memberof Log
     */
    serviceClassify: LogServiceClassifyEnum;
    /**
     * 来自
     * @type {string}
     * @memberof Log
     */
    source?: string;
    /**
     * 发送到
     * @type {string}
     * @memberof Log
     */
    destination?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof Log
     */
    status?: TaskStatus;
    /**
     * 
     * @type {TaskStage}
     * @memberof Log
     */
    stage?: TaskStage;
    /**
     * 请求参数
     * @type {string}
     * @memberof Log
     */
    request?: string;
    /**
     * 响应参数
     * @type {string}
     * @memberof Log
     */
    response?: string;
    /**
     * Id
     * @type {number}
     * @memberof Log
     */
    id?: number;
    /**
     * 创建时间
     * @type {Date}
     * @memberof Log
     */
    createdAt?: Date;
    /**
     * 修改时间
     * @type {Date}
     * @memberof Log
     */
    modifiedAt?: Date;
}


/**
 * @export
 */
export const LogServiceClassifyEnum = {
    B2B_RETURN: 'B2B_RETURN',
    B2C_RETURN: 'B2C_RETURN',
    B2C_CHANGE: 'B2C_CHANGE'
} as const;
export type LogServiceClassifyEnum = typeof LogServiceClassifyEnum[keyof typeof LogServiceClassifyEnum];


/**
 * Check if a given object implements the Log interface.
 */
export function instanceOfLog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "billCode" in value;
    isInstance = isInstance && "serviceClassify" in value;

    return isInstance;
}

export function LogFromJSON(json: any): Log {
    return LogFromJSONTyped(json, false);
}

export function LogFromJSONTyped(json: any, ignoreDiscriminator: boolean): Log {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
        'billCode': json['billCode'],
        'serviceClassify': json['serviceClassify'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'destination': !exists(json, 'destination') ? undefined : json['destination'],
        'status': !exists(json, 'status') ? undefined : TaskStatusFromJSON(json['status']),
        'stage': !exists(json, 'stage') ? undefined : TaskStageFromJSON(json['stage']),
        'request': !exists(json, 'request') ? undefined : json['request'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function LogToJSON(value?: Log | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskId': value.taskId,
        'billCode': value.billCode,
        'serviceClassify': value.serviceClassify,
        'source': value.source,
        'destination': value.destination,
        'status': TaskStatusToJSON(value.status),
        'stage': TaskStageToJSON(value.stage),
        'request': value.request,
        'response': value.response,
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
    };
}

