/* tslint:disable */
/* eslint-disable */
/**
 * 中间件接口文档
 * 聪耀，轻流中间件接口文档
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: qgm1993@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 状态
 * @export
 */
export const TaskStatus = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
    DOING: 'DOING'
} as const;
export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];


export function TaskStatusFromJSON(json: any): TaskStatus {
    return TaskStatusFromJSONTyped(json, false);
}

export function TaskStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStatus {
    return json as TaskStatus;
}

export function TaskStatusToJSON(value?: TaskStatus | null): any {
    return value as any;
}

