/* tslint:disable */
/* eslint-disable */
/**
 * 中间件接口文档
 * 聪耀，轻流中间件接口文档
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: qgm1993@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskServiceClassify } from './TaskServiceClassify';
import {
    TaskServiceClassifyFromJSON,
    TaskServiceClassifyFromJSONTyped,
    TaskServiceClassifyToJSON,
} from './TaskServiceClassify';
import type { TaskStage } from './TaskStage';
import {
    TaskStageFromJSON,
    TaskStageFromJSONTyped,
    TaskStageToJSON,
} from './TaskStage';
import type { TaskStatus } from './TaskStatus';
import {
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
} from './TaskStatus';

/**
 * 
 * @export
 * @interface TaskReq
 */
export interface TaskReq {
    /**
     * 单据编号
     * @type {string}
     * @memberof TaskReq
     */
    billCode: string;
    /**
     * 目标仓库
     * @type {string}
     * @memberof TaskReq
     */
    warehouse?: string;
    /**
     * 
     * @type {TaskServiceClassify}
     * @memberof TaskReq
     */
    serviceClassify?: TaskServiceClassify;
    /**
     * 报文信息
     * @type {string}
     * @memberof TaskReq
     */
    report?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof TaskReq
     */
    status?: TaskStatus;
    /**
     * 
     * @type {TaskStage}
     * @memberof TaskReq
     */
    stage?: TaskStage;
}

/**
 * Check if a given object implements the TaskReq interface.
 */
export function instanceOfTaskReq(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "billCode" in value;

    return isInstance;
}

export function TaskReqFromJSON(json: any): TaskReq {
    return TaskReqFromJSONTyped(json, false);
}

export function TaskReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskReq {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billCode': json['billCode'],
        'warehouse': !exists(json, 'warehouse') ? undefined : json['warehouse'],
        'serviceClassify': !exists(json, 'serviceClassify') ? undefined : TaskServiceClassifyFromJSON(json['serviceClassify']),
        'report': !exists(json, 'report') ? undefined : json['report'],
        'status': !exists(json, 'status') ? undefined : TaskStatusFromJSON(json['status']),
        'stage': !exists(json, 'stage') ? undefined : TaskStageFromJSON(json['stage']),
    };
}

export function TaskReqToJSON(value?: TaskReq | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billCode': value.billCode,
        'warehouse': value.warehouse,
        'serviceClassify': TaskServiceClassifyToJSON(value.serviceClassify),
        'report': value.report,
        'status': TaskStatusToJSON(value.status),
        'stage': TaskStageToJSON(value.stage),
    };
}

