/* tslint:disable */
/* eslint-disable */
/**
 * 中间件接口文档
 * 聪耀，轻流中间件接口文档
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: qgm1993@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 当前页面
     * @type {number}
     * @memberof Pagination
     */
    pageNum?: number;
    /**
     * 一页条数
     * @type {number}
     * @memberof Pagination
     */
    pageSize?: number;
    /**
     * 条目总数
     * @type {number}
     * @memberof Pagination
     */
    total?: number;
}

/**
 * Check if a given object implements the Pagination interface.
 */
export function instanceOfPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaginationFromJSON(json: any): Pagination {
    return PaginationFromJSONTyped(json, false);
}

export function PaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNum': !exists(json, 'pageNum') ? undefined : json['pageNum'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function PaginationToJSON(value?: Pagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNum': value.pageNum,
        'pageSize': value.pageSize,
        'total': value.total,
    };
}

