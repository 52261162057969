import Modal from "antd/es/modal/Modal";
import React, { useState } from "react";

interface ConfirmModelProps {
  children?: React.ReactNode;
  open: boolean;
  handleConfirm: () => Promise<void>;
  handleCancel: () => void;
}

const ConfirmModel: React.FC<ConfirmModelProps> = ({
  open,
  handleConfirm,
  handleCancel,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const doHandleConfirm = async () => {
    await handleConfirm();
    setConfirmLoading(false);
    handleCancel();
  };

  const handleOk = () => {
    setConfirmLoading(true);
    doHandleConfirm();
  };

  return (
    <Modal
      title="确认删除"
      centered
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    ></Modal>
  );
};

export default ConfirmModel;
