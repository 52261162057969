import { Col, Divider, Drawer, Row } from 'antd';
import { useEffect, useState } from 'react';
import { LogItem } from '../module/log';
import {TaskItem} from '../module/task';
import {StageValueEnum, statusValueEnum, WebClient} from './TableWithSearch';
import {TaskStage, TaskStatus} from "../api";

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
  color?: string;
}

const DescriptionItem = ({ title, content, color }: DescriptionItemProps) => (
  <div className="site-description-item-profile-wrapper">
      <p className="site-description-item-profile-p-label">{title}:</p>
      <span style={color===undefined?{}:{color:color, fontWeight: 800}}>{content}</span>
  </div>
);

interface LogRowProps {
  log: LogItem
}

const LogRow = ({ log }: LogRowProps) => (
  <>
    <p className="site-description-item-profile-p">{StageValueEnum[log.stage as TaskStage].text}</p>
    <Row>
      <Col span={12}>
        <DescriptionItem title="From" content={log.source} />
      </Col>
      <Col span={12}>
        <DescriptionItem title="To" content={log.destination} />
      </Col>
    </Row>
    <Row>
      <Col span={12}>
        <DescriptionItem title="执行结果" content={statusValueEnum[log.status as TaskStatus].text} color={log.status as TaskStatus === TaskStatus.FAIL?'red':'green'}/>
      </Col>
      <Col span={12}>
        <DescriptionItem title="执行时间" content={log.createdAt.toLocaleString()} />
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <DescriptionItem
          title="Request Body"
          content={log.request}
        />
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <DescriptionItem
          title="Response Body"
          content={log.response}
        />
      </Col>
    </Row>
    <Divider />
  </>
)

interface LogDrawerProps {
  open: boolean
  selectedTask: TaskItem | undefined
  logs?:LogItem[]
  handleClose: () => void
}

const LogDrawer = ({ open, handleClose, selectedTask }: LogDrawerProps) => {

  const [logs, setLogs] = useState<LogItem[]>([]);

  useEffect( () => {
    const getData = async () => {
      const listDataSource: LogItem[] = [];
      if (selectedTask === undefined) {
        return
      }
      await WebClient.listLogs({taskId: selectedTask.id})
        .then(res => {

          res.logs?.forEach(l => {
            listDataSource.push(l as unknown as LogItem)
          })
        });
      setLogs(listDataSource)
    };
    getData()
    // setLogs(listDataSource);
  }, [selectedTask])


  return <Drawer
    title={`${selectedTask?.billCode}-链路日志`}
    placement="right"
    size={'large'}
    onClose={handleClose}
    open={open}>
      {logs && logs.map((log, idx) => (
        <LogRow key={idx}
          log={log}
        ></LogRow>
      ))}
  </Drawer>
}

export default LogDrawer;
