/* tslint:disable */
/* eslint-disable */
/**
 * 中间件接口文档
 * 聪耀，轻流中间件接口文档
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: qgm1993@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskStage } from './TaskStage';
import {
    TaskStageFromJSON,
    TaskStageFromJSONTyped,
    TaskStageToJSON,
} from './TaskStage';
import type { TaskStatus } from './TaskStatus';
import {
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
} from './TaskStatus';

/**
 * 
 * @export
 * @interface LogReq
 */
export interface LogReq {
    /**
     * taskId
     * @type {number}
     * @memberof LogReq
     */
    taskId?: number;
    /**
     * 单据编号
     * @type {string}
     * @memberof LogReq
     */
    billCode: string;
    /**
     * 单据类型
     * @type {string}
     * @memberof LogReq
     */
    serviceClassify: LogReqServiceClassifyEnum;
    /**
     * 来自
     * @type {string}
     * @memberof LogReq
     */
    source?: string;
    /**
     * 发送到
     * @type {string}
     * @memberof LogReq
     */
    destination?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof LogReq
     */
    status?: TaskStatus;
    /**
     * 
     * @type {TaskStage}
     * @memberof LogReq
     */
    stage?: TaskStage;
    /**
     * 请求参数
     * @type {string}
     * @memberof LogReq
     */
    request?: string;
    /**
     * 响应参数
     * @type {string}
     * @memberof LogReq
     */
    response?: string;
}


/**
 * @export
 */
export const LogReqServiceClassifyEnum = {
    B2B_RETURN: 'B2B_RETURN',
    B2C_RETURN: 'B2C_RETURN',
    B2C_CHANGE: 'B2C_CHANGE'
} as const;
export type LogReqServiceClassifyEnum = typeof LogReqServiceClassifyEnum[keyof typeof LogReqServiceClassifyEnum];


/**
 * Check if a given object implements the LogReq interface.
 */
export function instanceOfLogReq(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "billCode" in value;
    isInstance = isInstance && "serviceClassify" in value;

    return isInstance;
}

export function LogReqFromJSON(json: any): LogReq {
    return LogReqFromJSONTyped(json, false);
}

export function LogReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogReq {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
        'billCode': json['billCode'],
        'serviceClassify': json['serviceClassify'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'destination': !exists(json, 'destination') ? undefined : json['destination'],
        'status': !exists(json, 'status') ? undefined : TaskStatusFromJSON(json['status']),
        'stage': !exists(json, 'stage') ? undefined : TaskStageFromJSON(json['stage']),
        'request': !exists(json, 'request') ? undefined : json['request'],
        'response': !exists(json, 'response') ? undefined : json['response'],
    };
}

export function LogReqToJSON(value?: LogReq | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskId': value.taskId,
        'billCode': value.billCode,
        'serviceClassify': value.serviceClassify,
        'source': value.source,
        'destination': value.destination,
        'status': TaskStatusToJSON(value.status),
        'stage': TaskStageToJSON(value.stage),
        'request': value.request,
        'response': value.response,
    };
}

