/* eslint-disable jsx-a11y/anchor-is-valid */
import type {
  ActionType,
  FormInstance,
  ProColumns,
} from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import {
  Button,
  ConfigProvider,
  message,
  Space,
  Spin,
  Table,
  theme,
  Tooltip,
} from "antd";
import { useRef, useState } from "react";
import { Configuration, TaskStage, TaskStatus, WebApi } from "../api";
import { TaskItem } from "../module/task";
import ConfirmModel from "./ConfirmModel";
import LogDrawer from "./LogDrawer";
import { DownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { fnGetFileNameFromContentDispositionHeader } from "../api/runtime-ext";
import {areAllPropertiesEmpty} from "../util/utils";

let showLogDrawer: () => void;

// let showConfirmDialog: () => void;
let selectTask: (task?: TaskItem) => void;

let handleConfirm: () => Promise<void>;

export const serviceClassifyValueEnum = {
  B2B_RETURN: {
    text: "B2B退货",
  },
  B2C_RETURN: {
    text: "B2C退货",
  },
  B2C_CHANGE: {
    text: "B2C换货",
  },
};

export const StageValueEnum = {
  RECEIVED: {
    text: "中间件接收",
  },
  SEND_TO_QF: {
    text: "推送至轻流",
  },
  QF_RECEIVED: {
    text: "轻流消费",
  },
  QF_EXPRESS_ORDER: {
    text: "快递下单",
  },
  CALLBACK: {
    text: "回传给OMS",
  },
  RECEIVED_UPDATE: {
    text: "中间件接收(更新)",
  },
  SEND_TO_QF_UPDATE: {
    text: "推送至轻流(更新)",
  },
  QF_RECEIVED_UPDATE: {
    text: "轻流消费(更新)",
  },
  RECEIVED_CANCEL: {
    text: "中间件接收(取消)",
  },
  SEND_TO_QF_CANCEL: {
    text: "推送至轻流(取消)",
  },
  QF_RECEIVED_CANCEL: {
    text: "轻流消费(取消)",
  },
};

export const statusValueEnum = {
  SUCCESS: {
    text: "成功",
    status: "Success",
  },
  FAIL: {
    text: "失败",
    status: "Error",
  },
  DOING: {
    text: "进行中",
    status: "Success",
  },
};
const columns: ProColumns<TaskItem>[] = [
  {
    title: "编号",
    dataIndex: "id",
    width: 50,
    hideInSearch: true,
  },
  {
    title: "单据号码",
    dataIndex: "billCode",
    copyable: true,
    ellipsis: false,
    tip: "订单编号",
  },
  {
    title: "目标仓库",
    dataIndex: "warehouse",
    hideInSearch: true,
  },
  {
    title: "服务类型",
    dataIndex: "serviceClassify",
    copyable: false,
    ellipsis: false,
    filters: true,
    onFilter: true,
    valueEnum: serviceClassifyValueEnum,
  },
  {
    disable: true,
    title: "执行阶段",
    dataIndex: "stage",
    filters: true,
    onFilter: true,
    ellipsis: false,
    valueType: "select",
    valueEnum: Object.fromEntries(
      Object.entries(StageValueEnum).filter(
        ([k, v]) => k !== "QF_EXPRESS_ORDER"
      )
    ),
  },
  {
    disable: true,
    title: "执行结果",
    dataIndex: "status",
    filters: true,
    onFilter: true,
    ellipsis: false,
    valueType: "select",
    valueEnum: statusValueEnum,
  },
  {
    title: "接收时间",
    key: "showTime",
    dataIndex: "createdAt",
    valueType: "dateTime",
    sorter: true,
    hideInSearch: true,
  },
  {
    title: "接收时间",
    dataIndex: "createdAt",
    valueType: "dateTimeRange",
    hideInTable: true,
    search: {
      transform: (value) => {
        return {
          startTime: new Date(value?.[0]),
          endTime: new Date(value?.[1]),
        };
      },
    },
  },
  {
    title: "修改时间",
    dataIndex: "modifiedAt",
    valueType: "dateTime",
    sorter: true,
    hideInSearch: true,
  },
  {
    title: "操作",
    valueType: "option",
    key: "option",
    render: (text, record, _, action) => [
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        onClick={() => {
          showLogDrawer();
          selectTask(record);
        }}
      >
        查看
      </a>,
      (record.stage === TaskStage.QF_RECEIVED &&
        record.status === TaskStatus.FAIL) ||
      (record.stage === TaskStage.QF_RECEIVED_UPDATE &&
        record.status === TaskStatus.FAIL) ||
      (record.stage === TaskStage.QF_RECEIVED_CANCEL &&
        record.status === TaskStatus.FAIL) ? (
        <a
          onClick={async () => {
            await WebClient.pushTaskAgain({ id: record.id });
            action?.reload(true);
          }}
        >
          {" "}
          重推{" "}
        </a>
      ) : (
        <></>
      ),
      // <TableDropdown
      //   key="actionGroup"
      //   onSelect={() => action?.reload()}
      //   menus={[
      //     {
      //       key: 'delete',
      //       name: '删除',
      //       onClick: () => {
      //         showConfirmDialog()
      //         handleConfirm = async () => {
      //           await WebClient.removeTask({id: [record.id]});
      //           action?.reload()
      //         }
      //       }
      //     },
      //   ]}
      // />,
    ],
  },
];

const prod_base_url = 'https://cy.ecolite.co/'
// const prod_base_url = "http://42.194.133.176:8080";
// const prod_base_url = "http://localhost:8080";

export const WebClient = new WebApi(
  new Configuration({ basePath: prod_base_url })
);

const Element = () => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();
  const [openLog, setOpenLog] = useState(false);
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const [selectedTask, setSelectedTask] = useState<TaskItem>();
  const [spinning, setSpinning] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();

  showLogDrawer = () => {
    setOpenLog(true);
  };

  // showConfirmDialog = () => {
  //   setOpenConfirmModal(true);
  // }

  selectTask = (task) => {
    setSelectedTask(task);
  };

  return (
    <>
      {/* 消息弹窗 */}
      {contextHolder}
      <Spin spinning={spinning} size="large" fullscreen/>
      <ConfirmModel
        open={openConfirmModel}
        handleConfirm={handleConfirm}
        handleCancel={() => {
          setOpenConfirmModel(false);
        }}
      ></ConfirmModel>
      <LogDrawer
        open={openLog}
        handleClose={() => {
          setOpenLog(false);
        }}
        selectedTask={selectedTask}
      ></LogDrawer>
      <ProTable<TaskItem>
        columns={columns}
        rowSelection={{
          // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
          // 注释该行则默认不显示下拉选项
          selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
          // defaultSelectedRowKeys: [1],
        }}
        tableAlertRender={({
          selectedRowKeys,
          selectedRows,
          onCleanSelected,
        }) => {
          console.log(selectedRowKeys, selectedRows);
          return (
            <Space size={24}>
              <span>
                已选 {selectedRowKeys.length} 项
                <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                  取消选择
                </a>
              </span>
            </Space>
          );
        }}
        tableAlertOptionRender={({ selectedRows, onCleanSelected }) => {
          return (
            <>
              <Space size={16}>
                {selectedRows.filter(
                  (row) =>
                    (row.stage === TaskStage.QF_RECEIVED &&
                      row.status === TaskStatus.FAIL) ||
                    (row.stage === TaskStage.QF_RECEIVED_CANCEL &&
                      row.status === TaskStatus.FAIL) ||
                    (row.stage === TaskStage.QF_RECEIVED_UPDATE &&
                      row.status === TaskStatus.FAIL)
                ).length !== 0 ? (
                  <a
                    onClick={async () => {
                      selectedRows
                        .filter((row) => row.status !== TaskStatus.SUCCESS)
                        .forEach((failed) => {
                          WebClient.pushTaskAgain({ id: failed.id });
                          actionRef.current?.reload();
                        });
                    }}
                  >
                    批量重推
                  </a>
                ) : (
                  <></>
                )}
                {/*<a style={{color: 'red'}}*/}
                {/*   onClick={() => {*/}
                {/*     showConfirmDialog()*/}
                {/*     handleConfirm = async () => {*/}
                {/*       await WebClient.removeTask({id: selectedRows.map(t => t.id)})*/}
                {/*       onCleanSelected()*/}
                {/*       actionRef.current?.reload()*/}
                {/*     }*/}
                {/*   }}>*/}
                {/*  批量删除*/}
                {/*</a>*/}
              </Space>
            </>
          );
        }}
        actionRef={actionRef}
        formRef={formRef}
        cardBordered
        request={async (params = {}, sort, filter) => {
          console.log(
            `params: ${JSON.stringify(params)}`,
            `sort: ${JSON.stringify(sort)}`,
            `filter: ${JSON.stringify(filter)}`
          );
          return WebClient.listTasks({
            pageNum: params.current,
            pageSize: params.pageSize,
            billCode: params.billCode,
            serviceClassify: params.serviceClassify,
            stage: params.stage,
            status: params.status,
            startTime: params.startTime,
            endTime: params.endTime,
          }).then((res) => {
            const tableListDataSource: TaskItem[] = [];
            res.tasks?.forEach((t) =>
              tableListDataSource.push(t as unknown as TaskItem)
            );
            return {
              data: tableListDataSource,
              success: true,
              total: res.pagination?.total,
            };
          });
        }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
          onChange(value) {
            console.log("value: ", value);
          },
        }}
        rowKey="id"
        search={{
          defaultCollapsed: false,
          labelWidth: "auto",
        }}
        defaultSize={"small"}
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        form={{
          // 由于配置了 transform，提交的参数与定义的不同这里需要转化一下
          syncToUrl: (values, type) => {
            if (type === "get") {
              return {
                ...values,
                created_at: [values.startTime, values.endTime],
              };
            }
            return values;
          },
        }}
        pagination={{
          onChange: (page, pageSize) => console.log(page, pageSize),
        }}
        dateFormatter="string"
        toolbar={{
          actions: [
            <>
              <Tooltip
                className={"ant-form-item-tooltip"}
                title={"最多只能导出查询结果中的前5000条"}
              >
                <QuestionCircleOutlined></QuestionCircleOutlined>
              </Tooltip>
              <Button
                type={"primary"}
                icon={<DownloadOutlined />}
                onClick={() => {
                  setSpinning(true);
                  console.debug("pageInfo", actionRef.current?.pageInfo);
                  console.debug("form", formRef.current?.getFieldsValue());
                  const params = formRef.current?.getFieldsValue();

                  if (areAllPropertiesEmpty(params)) {
                    setSpinning(false);
                    messageApi.open({
                      type: "error",
                      content: "请先添加筛选条件",
                    });
                    return;
                  }

                  WebClient.downloadTasksRaw({
                    billCode: params.billCode,
                    serviceClassify: params.serviceClassify,
                    stage: params.stage,
                    status: params.status,
                    startTime: params.createdAt?.[0],
                    endTime: params.createdAt?.[1],
                  })
                    .then(async (res) => {
                      var file = new Blob([await res.value()], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      });
                      var fileURL = URL.createObjectURL(file);

                      // create <a> tag dinamically
                      var fileLink = document.createElement("a");
                      fileLink.href = fileURL;
                      // it forces the name of the downloaded file
                      fileLink.download =
                        fnGetFileNameFromContentDispositionHeader(res.raw);

                      // 隐藏 spin
                      setSpinning(false);
                      // triggers the click event
                      fileLink.click();
                      fileLink.remove();
                    })
                    .catch((err) => {
                      // 隐藏 spin
                      setSpinning(false);
                      // 弹出 alert
                      messageApi.open({
                        type: "error",
                        content: "下载文件失败，请联系管理员",
                      });
                    });
                }}
              >
                导出查询结果
              </Button>
            </>,
          ],
        }}
      />
    </>
  );
};

const NodeElement = () => {
  const themeConfig = {
    algorithm: [theme.compactAlgorithm],
  };
  return (
    <ConfigProvider theme={themeConfig}>
      <Element></Element>
    </ConfigProvider>
  );
};

export default NodeElement;
