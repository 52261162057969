export class BlobAndFNameApiResponse {
  constructor(public raw: Response) {}

  async value(): Promise<{ blob: Blob; filename: string }> {
    return {
      blob: await this.raw.blob(),
      filename: fnGetFileNameFromContentDispositionHeader(this.raw),
    };
  }
}

export let fnGetFileNameFromContentDispositionHeader = function (
  response: Response
) {
  const disposition = response.headers.get("content-disposition");
  let contentDisposition = disposition?.split(";") ?? [];
  let filename = contentDisposition[1].split("=")[1];
  filename = filename ? decodeURIComponent(filename.trim()) : "downloaded";
  return filename;
};
