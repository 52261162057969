/* tslint:disable */
/* eslint-disable */
/**
 * 中间件接口文档
 * 聪耀，轻流中间件接口文档
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: qgm1993@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListLogs200Response,
  ListTasks200Response,
  Log,
  LogReq,
  Task,
  TaskReq,
  TaskServiceClassify,
  TaskStage,
  TaskStatus,
} from '../models';
import {
    ListLogs200ResponseFromJSON,
    ListLogs200ResponseToJSON,
    ListTasks200ResponseFromJSON,
    ListTasks200ResponseToJSON,
    LogFromJSON,
    LogToJSON,
    LogReqFromJSON,
    LogReqToJSON,
    TaskFromJSON,
    TaskToJSON,
    TaskReqFromJSON,
    TaskReqToJSON,
    TaskServiceClassifyFromJSON,
    TaskServiceClassifyToJSON,
    TaskStageFromJSON,
    TaskStageToJSON,
    TaskStatusFromJSON,
    TaskStatusToJSON,
} from '../models';

export interface CreateLogRequest {
    logReq: LogReq;
}

export interface CreateTaskRequest {
    taskReq: TaskReq;
}

export interface DownloadTasksRequest {
    billCode?: string;
    serviceClassify?: Array<TaskServiceClassify>;
    stage?: Array<TaskStage>;
    status?: TaskStatus;
    startTime?: Date;
    endTime?: Date;
}

export interface GetTaskByIdRequest {
    id: number;
}

export interface ListLogsRequest {
    taskId: number;
}

export interface ListTasksRequest {
    pageNum?: number;
    pageSize?: number;
    billCode?: string;
    serviceClassify?: Array<TaskServiceClassify>;
    stage?: Array<TaskStage>;
    status?: TaskStatus;
    startTime?: Date;
    endTime?: Date;
}

export interface PushTaskAgainRequest {
    id: number;
}

export interface RemoveTaskRequest {
    id: Array<number>;
}

export interface UpdateTaskRequest {
    task: Task;
}

/**
 * 
 */
export class WebApi extends runtime.BaseAPI {

    /**
     * 记录请求链路
     * 新增日志
     */
    async createLogRaw(requestParameters: CreateLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Log>> {
        if (requestParameters.logReq === null || requestParameters.logReq === undefined) {
            throw new runtime.RequiredError('logReq','Required parameter requestParameters.logReq was null or undefined when calling createLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/logs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogReqToJSON(requestParameters.logReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogFromJSON(jsonValue));
    }

    /**
     * 记录请求链路
     * 新增日志
     */
    async createLog(requestParameters: CreateLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Log> {
        const response = await this.createLogRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 根据巨易工单新增任务
     * 新增任务(for test)
     */
    async createTaskRaw(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters.taskReq === null || requestParameters.taskReq === undefined) {
            throw new runtime.RequiredError('taskReq','Required parameter requestParameters.taskReq was null or undefined when calling createTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskReqToJSON(requestParameters.taskReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     * 根据巨易工单新增任务
     * 新增任务(for test)
     */
    async createTask(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Task> {
        const response = await this.createTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 返回excel
     * 下载tasks
     */
    async downloadTasksRaw(requestParameters: DownloadTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.billCode !== undefined) {
            queryParameters['billCode'] = requestParameters.billCode;
        }

        if (requestParameters.serviceClassify) {
            queryParameters['serviceClassify'] = requestParameters.serviceClassify;
        }

        if (requestParameters.stage) {
            queryParameters['stage'] = requestParameters.stage;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.startTime !== undefined) {
            queryParameters['startTime'] = (requestParameters.startTime as any).toISOString();
        }

        if (requestParameters.endTime !== undefined) {
            queryParameters['endTime'] = (requestParameters.endTime as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks-download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * 返回excel
     * 下载tasks
     */
    async downloadTasks(requestParameters: DownloadTasksRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 返回任务详情
     * 获取任务详情
     */
    async getTaskByIdRaw(requestParameters: GetTaskByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Task>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTaskById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskFromJSON));
    }

    /**
     * 返回任务详情
     * 获取任务详情
     */
    async getTaskById(requestParameters: GetTaskByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Task>> {
        const response = await this.getTaskByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 返回日志列表
     * 查询日志列表
     */
    async listLogsRaw(requestParameters: ListLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLogs200Response>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling listLogs.');
        }

        const queryParameters: any = {};

        if (requestParameters.taskId !== undefined) {
            queryParameters['taskId'] = requestParameters.taskId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/logs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLogs200ResponseFromJSON(jsonValue));
    }

    /**
     * 返回日志列表
     * 查询日志列表
     */
    async listLogs(requestParameters: ListLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLogs200Response> {
        const response = await this.listLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 返回任务列表
     * 查询任务列表
     */
    async listTasksRaw(requestParameters: ListTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTasks200Response>> {
        const queryParameters: any = {};

        if (requestParameters.pageNum !== undefined) {
            queryParameters['pageNum'] = requestParameters.pageNum;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.billCode !== undefined) {
            queryParameters['billCode'] = requestParameters.billCode;
        }

        if (requestParameters.serviceClassify) {
            queryParameters['serviceClassify'] = requestParameters.serviceClassify;
        }

        if (requestParameters.stage) {
            queryParameters['stage'] = requestParameters.stage;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.startTime !== undefined) {
            queryParameters['startTime'] = (requestParameters.startTime as any).toISOString();
        }

        if (requestParameters.endTime !== undefined) {
            queryParameters['endTime'] = (requestParameters.endTime as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTasks200ResponseFromJSON(jsonValue));
    }

    /**
     * 返回任务列表
     * 查询任务列表
     */
    async listTasks(requestParameters: ListTasksRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTasks200Response> {
        const response = await this.listTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 返回日志列表
     * 查询日志列表
     */
    async pushTaskAgainRaw(requestParameters: PushTaskAgainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pushTaskAgain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks/{id}/push`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 返回日志列表
     * 查询日志列表
     */
    async pushTaskAgain(requestParameters: PushTaskAgainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pushTaskAgainRaw(requestParameters, initOverrides);
    }

    /**
     * 删除
     * 删除任务
     */
    async removeTaskRaw(requestParameters: RemoveTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 删除
     * 删除任务
     */
    async removeTask(requestParameters: RemoveTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeTaskRaw(requestParameters, initOverrides);
    }

    /**
     * update by replace
     * 修改任务(for test)
     */
    async updateTaskRaw(requestParameters: UpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Task>>> {
        if (requestParameters.task === null || requestParameters.task === undefined) {
            throw new runtime.RequiredError('task','Required parameter requestParameters.task was null or undefined when calling updateTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaskToJSON(requestParameters.task),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskFromJSON));
    }

    /**
     * update by replace
     * 修改任务(for test)
     */
    async updateTask(requestParameters: UpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Task>> {
        const response = await this.updateTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
